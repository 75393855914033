import './dns-card.scss';
import Honeycomb from "../../backend/Honeycomb";
import BaseCard from "../BaseCard";


const DNSCard = ({query = '', onCount = () => {}, className = '', ...rest}) => {
    const hdb = new Honeycomb();
    return <BaseCard
        query={query} onCount={onCount} className={'xf-dns-card ' + className} downloadable expands
        onDownload={hdb.dns_dl} source={hdb.dns} count={hdb.dns_count} cancel={hdb.cancel_token}
        title="DNS Records" description="Associated DNS records" dataKey="value"
        headers={[
            {key: 'name', header: 'Name'},
            {key: 'type', header: 'Type'},
        ]}
        {...rest}
    />;
};

export default DNSCard;
