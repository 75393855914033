import React from 'react';
import {Loading as Spinner} from "@carbon/react";
import {formatNumber} from "../../helpers/Utilities";

const TotalCard = (
    {value = 0, className = '', label = '', spinLabel = 'Calculating...'}
) =>
    <div className={'xf-i-stat ' + className}>
        {value >= 0 ?
            <h3>{formatNumber(value)}</h3> :
            <Spinner small withOverlay={false} description={spinLabel} />}
        <span>{label}</span>
    </div>;

export default TotalCard;
