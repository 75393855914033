import React, {Component} from 'react';
import {
    Header,
    HeaderContainer,
    HeaderGlobalAction,
    HeaderGlobalBar,
    HeaderMenuButton,
    HeaderMenuItem,
    HeaderName,
    HeaderNavigation,
    HeaderPanel,
    HeaderSideNavItems,
    SideNav,
    SideNavItems,
    SkipToContent,
    Switcher,
    SwitcherDivider,
    SwitcherItem
} from "@carbon/react";
import {IbmSecurity} from "@carbon/icons-react";
import {Link} from "react-router-dom";
import SessionContext from "../../helpers/SessionContext";
import Account from "../../backend/Account";

import './main-header.scss';
import NavigationHelper from "../../helpers/Navigation";
import {SSO_ENDPOINT} from "../../backend/Config";


class MainHeader extends Component {
    static defaultProps = {stats: {}};
    state = {panelActive: false};
    render() {
        const { stats } = this.props;
        return (
            <HeaderContainer
                render={({ isSideNavExpanded, onClickSideNavExpand }) => (
                    <Header aria-label="Red Hive Header" className="xfr-main-header">
                        <SkipToContent />
                        <HeaderMenuButton
                            aria-label="Open menu"
                            onClick={onClickSideNavExpand}
                            isActive={isSideNavExpanded}
                        />
                        <div className="header-xfr-icon">
                            <IbmSecurity size={20} />
                        </div>
                        <HeaderName element={Link} to="/" prefix="X-Force Red">
                            <span className="xfr-divider">|</span>RedHive
                            <span className="hive-ico">
                                <svg className="hivelogo" width="25" height="25" role="img" alt="redhive" viewBox="0 0 500 500" version="1.1">
                                    <polygon stroke="#f4f4f4" fill="none" strokeWidth="25" points="288.4225616455078,80.70246887207031 184.02182006835938,23.008636474609375 80.23545837402344,82.64189910888672 80.84941101074219,199.9697265625 185.2501678466797,257.66357421875 289.0369110107422,198.02952575683594 " id="svg_2"/>
                                    <polygon stroke="#97354d" fill="none" strokeWidth="25" points="211.57749938964844,419.29754638671875 315.97825622558594,476.9913330078125 419.76454162597656,417.3580322265625 419.1505889892578,300.0302734375 314.7499237060547,242.33645629882812 210.96310424804688,301.970458984375 " id="svg_3"/>
                                </svg>
                            </span>
                        </HeaderName>
                        <HeaderNavigation aria-label="RedHive">
                            <SessionContext.Consumer>
                                {s => s.user ? NavigationHelper.getHeaderMenuItems() :
                                    <HeaderMenuItem element="a" href={SSO_ENDPOINT} key="sso-redirect" target="_self">Login with w3id</HeaderMenuItem>
                                }
                            </SessionContext.Consumer>
                        </HeaderNavigation>
                        <HeaderGlobalBar>
                            {stats && stats.total > 0 && <div className="hdb-stats">
                                <div className="hdb-stat">
                                    <div className="hs-lbl cds--label">Breach Records: </div>
                                    <div className="hs-val cds--label">{stats.breach.toLocaleString()}</div>
                                </div>
                                <div className="hdb-stat">
                                    <div className="hs-lbl cds--label">Network Assets: </div>
                                    <div className="hs-val cds--label">{stats.network.toLocaleString()}</div>
                                </div>
                            </div>}
                            <SessionContext.Consumer>
                                {s => s.user &&
                                    <HeaderGlobalAction aria-label="User Options" className="xfr-profile-pic" tooltipAlignment="end"
                                                        onClick={() => this.setState(s => ({panelActive: !s.panelActive}))} key="user">
                                        <img src={Account.PHOTO} alt={'forename' in s.user ? s.user.forename : 'User'}/>
                                    </HeaderGlobalAction>
                                }
                            </SessionContext.Consumer>
                        </HeaderGlobalBar>
                        <SessionContext.Consumer>
                            {s => s.user &&
                                <HeaderPanel aria-label="Header Panel" expanded={this.state.panelActive} className="xfr-side-panel">
                                    <div className="xfr-hp-inner">
                                        <div className="xfr-user-info">
                                            <div className="xui-head">Logged in as:</div>
                                            <div className="xui-sub">{s.user.forename} {s.user.surname} ({s.user.nickname})</div>
                                            <div className="xui-sub">{s.user.email}</div>
                                        </div>
                                        <Switcher aria-label="User" className="xfr-switcher">
                                            <SwitcherDivider />
                                            <SwitcherItem aria-label="Logout" href={Account.LOGOUT}>Logout</SwitcherItem>
                                            <SwitcherDivider />
                                        </Switcher>
                                    </div>
                                </HeaderPanel>
                            }
                        </SessionContext.Consumer>
                        <SideNav
                            aria-label="Side navigation"
                            expanded={isSideNavExpanded}
                            isPersistent={false}>
                            <SideNavItems>
                                <HeaderSideNavItems>
                                    <SessionContext.Consumer>
                                        {s => s.user ? NavigationHelper.getHeaderMenuItems() :
                                            <HeaderMenuItem element="a" href={SSO_ENDPOINT} key="sso-redirect" target="_self">Login with w3id</HeaderMenuItem>
                                        }
                                    </SessionContext.Consumer>
                                </HeaderSideNavItems>
                            </SideNavItems>
                        </SideNav>
                    </Header>
                )}
            />
        );
    }
}

export default MainHeader;
