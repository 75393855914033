import './rel-card.scss';
import {Theme, Tooltip} from "@carbon/react";
import {WarningHex} from "@carbon/icons-react";
import Honeycomb from "../../backend/Honeycomb";
import BaseCard from "../BaseCard";
import React from "react";

const RelCardTitle = React.memo(() => <span className="xf-rel-warn">
    <span>Associated Domains</span>
    <div className="xf-rel-box">
        <Tooltip
            align="bottom-left"
            label="Warning: Reverse WHOIS queries often contain privacy protection services and management agencies so may have inaccuracies, please be vigilant when using this dataset.">
            <button type="button" className="xf-rel-icon">
                <WarningHex size={16} />
            </button>
        </Tooltip>
    </div>
</span>, () => true);

const RelCard = ({query = '', onCount = () => {}, className = '', ...rest}) => {
    const hdb = new Honeycomb();
    return <BaseCard
        query={query} onCount={onCount} className={'xf-rel-card ' + className} downloadable
        onDownload={hdb.rel_dl} source={hdb.rel} count={hdb.rel_count} cancel={hdb.cancel_token}
        title={<RelCardTitle/>}
        description="Domains which share registration information"
        headers={[
            {key: 'domain', header: 'Domain'},
            {key: 'email', header: 'Owner'},
            {key: 'name', header: 'Name'},
        ]}
        {...rest}
    />
};

export default RelCard;
