
export const formatNumber = (number, fractionDigits = 2, threshold = 1000000) => {
    if (threshold > 0 && number < threshold) {
        return number.toLocaleString();
    }

    const SI_SYMBOL = ["", "k", "m", "b", "t", "q", "s"];

    // what tier? (determines SI symbol)
    let tier = Math.log10(Math.abs(number)) / 3 | 0;

    // if zero, we don't need a suffix
    if(tier === 0) return number;

    // get suffix and determine scale
    let suffix = SI_SYMBOL[tier];
    let scale = Math.pow(10, tier * 3);

    // scale the number
    let scaled = number / scale;

    // format number and add suffix
    return scaled.toFixed(fractionDigits) + suffix;
}