import React from 'react';
import {
    Button,
    DataTable,
    Pagination,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableExpandedRow,
    TableExpandHeader,
    TableExpandRow,
    TableHead,
    TableHeader,
    TableRow,
    TableToolbar,
    TableToolbarContent,
    TableToolbarSearch
} from "@carbon/react";
import './data-card.scss';
import {CheckmarkOutline, CircleDash, Download} from "@carbon/icons-react";
import {formatNumber} from "../../helpers/Utilities";
import persistent from "@carbon/react/lib/components/DataTable/TableToolbarSearch";
import tooltipAlignment from "@carbon/react/lib/components/Button/Button";
import tooltipPosition from "@carbon/react/lib/components/Button/Button";

const _ = require('lodash');


export const PAGINATION_TEMPLATE = {
    total: -1,
    current_page: 1,
    item_count: 0,
    total_pages: 0,
    page_size: 100,
    has_next: false,
    has_prev: false,
};

const DownloadIcon = props => <Download size={16} />

// use a memoized table row, so we don't re-render all rows when expanding/collapsing one of them
const MemoTableRow = React.memo((
    {expandable = false, row = {}, rowProps = {}, content = [], headers = []}
) => expandable ?
    <React.Fragment key={row.id}>
        <TableExpandRow {...rowProps}>
            {row.cells.map((cell) => (
                <TableCell key={cell.id}>
                    {typeof cell.value == "boolean" ? (
                        cell.value ? <CheckmarkOutline size={16} /> : <CircleDash size={16} />
                    ) : cell.value}
                </TableCell>
            ))}
        </TableExpandRow>
        <TableExpandedRow colSpan={headers.length + 1}>
            <div>{content}</div>
        </TableExpandedRow>
    </React.Fragment>
    :
    <TableRow key={row.id} {...rowProps}>
        {row.cells.map((cell) => (
            <TableCell key={cell.id}>
                {typeof cell.value == "boolean" ? (
                    cell.value ? <CheckmarkOutline size={16} /> : <CircleDash size={16} />
                ) : cell.value}
            </TableCell>
        ))}
    </TableRow>,
    (prevProps, nextProps) =>
        (prevProps.expandable === nextProps.expandable) && (prevProps?.headers?.length === nextProps?.headers?.length)
        && _.isEqual(prevProps.row, nextProps.row) && _.isEqual(prevProps.headers, nextProps.headers)
);

const DataCard = (
    {
        contentGenerator = () => [],
        onPageChange = () => {},
        expandable = false,
        downloadable = false,
        onDownload = () => {},
        size = 'sm',
        title = '',
        className = '',
        description = '',
        rows = [],
        headers = [],
        pageSizes = [100, 250, 500, 1000, 1500, 2500],
        pagination = {...PAGINATION_TEMPLATE}
    }
) => {
    const pageCount = Math.ceil(pagination.total / pagination.page_size).toLocaleString(), totalAbb = formatNumber(pagination.total, 1);

    return (
        <div className={'xf-data-card ' + className}>
            <div className="xf-dc-content">
                <DataTable isSortable rows={rows} headers={headers} size={size} render={({
                    rows,
                    headers,
                    getHeaderProps,
                    getRowProps,
                    getTableProps,
                    getTableContainerProps,
                    onInputChange
                }) => (
                    <TableContainer title={title} description={description} stickyHeader {...getTableContainerProps()}>
                        <TableToolbar size="sm">
                            <TableToolbarContent className="xf-table-toolbar">
                                <TableToolbarSearch onChange={onInputChange} size="sm" />
                                {downloadable && <Button
                                    hasIconOnly renderIcon={DownloadIcon} onClick={onDownload}
                                    size="sm" tooltipAlignment="end" tooltipPosition="top"
                                    kind="ghost" iconDescription="Bulk download"
                                />}
                            </TableToolbarContent>
                        </TableToolbar>
                        <Table {...getTableProps()}>
                            <TableHead>
                                <TableRow>
                                    {expandable && <TableExpandHeader />}{headers.map(
                                        (header, i) => <TableHeader key={i} {...getHeaderProps({ header })}>
                                            {header.header}
                                        </TableHeader>)
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map(row => <MemoTableRow
                                    key={row.id} headers={headers} expandable={expandable} row={row}
                                    rowProps={getRowProps({ row })} content={contentGenerator(row)}
                                />)}
                            </TableBody>
                        </Table>
                        {pagination.total > 500000 ?
                            <Pagination page={pagination.current_page} pagesUnknown
                                        pageSize={pagination.page_size} pageSizes={pageSizes}
                                        isLastPage={!pagination.has_next} itemsPerPageText="Limit:"
                                        onChange={onPageChange} className="ready xl-page"
                                        pageText={statePage => `Page ${ statePage } of ${ pageCount }`}
                                        itemText={(min, max) => `${ min.toLocaleString() }–${ max.toLocaleString() }`}
                                        itemRangeText={(min, max) => `${ min.toLocaleString() }–${ max.toLocaleString() } of ${ totalAbb }`}
                            />
                            :
                            <Pagination page={pagination.current_page} totalItems={pagination.total}
                                        pageSize={pagination.page_size} isLastPage={!pagination.has_next}
                                        pageSizes={pageSizes} onChange={onPageChange} itemsPerPageText="Limit:"
                                        className={pagination.total >= 0 ? 'ready' : 'pending'}
                                        pageRangeText={(statePage,) => `Page ${ statePage } of ${ pageCount }`}
                                        itemText={(min, max) => `${ min.toLocaleString() }–${ max.toLocaleString() }`}
                                        itemRangeText={(min, max,) => `${ min.toLocaleString() }–${ max.toLocaleString() } of ${ totalAbb }`}
                            />
                        }
                    </TableContainer>
                    )} />
            </div>
        </div>
    );
};

export default DataCard;
