import React from 'react';

// Create a Context
const SessionContext = React.createContext({
    user: {}, toasts: [],
    addToast: (kind, title, subtitle, caption, timeout=3500) => {},
    networkFaultHandler: (event) => {}
});
// It returns an object with 2 values:
// { Provider, Consumer }

export default SessionContext;