import React, {useState} from 'react';
import {Button, Search} from "@carbon/react";
import {Search as SearchIcon} from "@carbon/icons-react";
import './search-bar.scss';

const SearchIcon16 = props => <SearchIcon size={16} {...props}/>;

const SearchBar = ({
    defaultValue = '',
    placeholder = '',
    onSubmit = () => {}
}) => {
    const [value, setValue] = useState(defaultValue);
    const submit = () => onSubmit(value);
    return (<>
        <Search id="search-intel" value={value} size="sm" labelText="Search"
                placeholder={placeholder} onKeyDown={e => (e.key === 'Enter') && submit()}
                onClear={() => setValue('')} onChange={e => setValue(e.target.value)}
        />
        <Button kind="primary" renderIcon={SearchIcon16} iconDescription="Search" size="sm" onClick={submit}>
            Search
        </Button>
    </>);
};

export default SearchBar;
