import React from 'react';
import {Routes} from "react-router-dom";
import MainHeader from "./components/MainHeader";
import {Button, ButtonSet, ToastNotification} from "@carbon/react";
import ParticleBanner from "./components/ParticleBanner";
import SessionContext from "./helpers/SessionContext";
import NavigationHelper from "./helpers/Navigation";
import Account from "./backend/Account";
import Honeycomb from "./backend/Honeycomb";

import './App.scss';

const honeycomb = new Honeycomb();

class App extends React.Component {
    constructor(props) {
        super(props);
        this._networkFaultHandler = this.networkFaultHandler;
        this._addToast = this.addToast;

        this.state = {
            user: {}, status: {}, toasts: [], addToast: this.addToast, networkFaultHandler: this.networkFaultHandler
        };
    }
    // kind: 'error' | 'info' | 'info-square' | 'success' | 'warning' | 'warning-alt'
    addToast = (kind, title, subtitle, caption, timeout=3500) => this.setState(
        s => ({toasts: [...s.toasts, {
            kind: kind, title: title, subtitle: subtitle, caption: caption, timeout: timeout
        }]})
    );
    addError = (message, caption='Please try again', title='Error') => this.addToast('error', title, message, caption);
    addInfo = (message, caption='Information', title='Information') => this.addToast('info', title, message, caption);
    addWarning = (message, caption='Warning', title='Warning') => this.addToast('warning', title, message, caption);
    addSuccess = (message, caption='Operation Successful', title='Success') => this.addToast('success', title, message, caption);
    networkFaultHandler = e => {
        console.log(e);
        if(!e.results) {
            if (!e.response || !e.response.results) return this.addError('A network error occurred');
            e.results = e.response.results;
        }
        if(typeof e.results !== 'object' || !('error' in e.results)) return this.addError('A network error occurred');
        return this.addError(e.results['error']);
    }
    componentWillUnmount = () => {
        this.networkFaultHandler = () => {};
        this.addError = () => {};
    }
    componentDidMount = () => {
        const self = this;
        this.networkFaultHandler = this._networkFaultHandler;
        this.addToast = this._addToast;

        Account.user().then(d => {
            if (d) {
                self.setState({user: d});

                honeycomb.status().then(d => {
                    if (d) self.setState({status: d});
                }).catch(self.networkFaultHandler);
            }
        }).catch(this.networkFaultHandler);
    }
    render = () => {
        return (
            <SessionContext.Provider value={this.state}>
                {this.state.user && 'authorised' in this.state.user && this.state.user.authorised ?
                    <div className="App">
                        <MainHeader stats={this.state.status.stats}/>
                        <Routes>{NavigationHelper.getRoutes()}</Routes>
                        <div className="toasty">
                            {this.state.toasts.map((t, i) =>
                                <ToastNotification
                                    key={i} kind={t.kind} subtitle={t.subtitle}
                                    title={t.title} caption={t.caption} timeout={t.timeout}
                                />
                            )}
                        </div>
                    </div>
                    :
                    <div className="App">
                        <div className="xf-unauthorised">
                            <ParticleBanner
                                fullScreen={true} speed={4} particleCount={1} particleFrequency={.1} particleLimit={500}
                                lineColour="#ba1b23" strokeColour="#da1e28" fillColour="#fa4d56"
                            />
                            <div className="auth-box">
                                <div className="auth-head">Authentication Required</div>
                                <div className="auth-body">
                                    Unauthorised access is prohibited, please log in with w3id.
                                </div>
                                <ButtonSet className="auth-foot">
                                    <Button kind="secondary" onClick={Account.bail}>Get me out of here!</Button>
                                    <Button kind="danger" onClick={Account.unified_auth}>Log in with w3id</Button>
                                </ButtonSet>
                            </div>
                        </div>
                    </div>
                }
            </SessionContext.Provider>
        );
    }
}

export default App;
