import axios from 'axios';
import {HIVE_HOST, HIVE_SCHEME, SSO_ENDPOINT, X_FORCE_URL} from "./Config";


export default class Account
{
    static API_URL = `${HIVE_SCHEME}://${HIVE_HOST}/api`;
    static LOGOUT = `${Account.API_URL}/account/logout`;
    static PHOTO = `${Account.API_URL}/account/photo`;
    static USER = `${Account.API_URL}/account/user`;
    static FAULT = `${Account.API_URL}/sso/fault`;

    static user = () => axios.get(Account.USER).then(response => response.data);
    static fault = () => axios.get(Account.FAULT).then(response => response.data);
    static unified_auth = () => window.location = SSO_ENDPOINT;
    static bail = () => window.location = X_FORCE_URL;
}
