import {useState} from 'react';
import './svc-card.scss';
import Honeycomb from "../../backend/Honeycomb";
import BaseCard from "../BaseCard";

const SvcCard = ({query = '', onCount = () => {}, className = '', ...rest}) => {
    const hdb = new Honeycomb();
    const [cache, setCache] = useState({});

    const processServiceData = (id, data) => {
        if (cache && (id in cache)) return cache[id];
        if (!data || !(id in data)) return;

        const src = data[id]?.data ?? '';
        const pad = src.length > 0 ? Array((4 - src.length % 4) % 4 + 1).join('=') : '';
        let content = src + pad;

        try {
            switch (data[id].protocol) {
                case 'http':
                case 'https':
                case 'etcd':
                case 'ftp':
                case 'telnet':  // Decode some common plaintext protocols
                    content = atob(content);
                    break;
                default:
                    break;
            }
        } catch (e) {
            console.error(e);
            content = src + pad;
        }

        setCache(c => {
            const _cache = c ?? {};
            _cache[id] = content;
            return _cache;
        });

        return content;
    }

    return <BaseCard
        query={query} onCount={onCount} className={'xf-services-card ' + className} size="sm" downloadable
        onDownload={hdb.svc_dl} source={hdb.svc} count={hdb.svc_count} cancel={hdb.cancel_token} expands
        title="Services" description="For all known IPv4 hosts"
        headers={[
            {key: 'address', header: 'Host'},
            {key: 'transport', header: 'Transport'},
            {key: 'protocol', header: 'Protocol'},
            {key: 'server', header: 'Server'},
            {key: 'port', header: 'Port'},
            {key: 'path', header: 'Path'},
            {key: 'response', header: 'Has Response?'},
        ]}
        parse={results => {
            const rows = [], data = {};
            Object.entries(results).forEach(([k, v]) => {
                rows.push({
                    id: k, address: v.address, server: v.server, port: v.port, path: v.path,
                    protocol: v.protocol, transport: v.transport, response: v.data.length > 0
                });
                data[k] = {
                    'protocol': v.protocol,
                    'data': v.data
                };
            })
            return [rows, data];
        }}
        generator={(row, data) => <div>{row.id in data && processServiceData(row.id, data)}</div>}
        {...rest}
    />
};

export default SvcCard;
