import React from 'react';
import {Link, Route} from "react-router-dom";
import Home from "../../content/Home";
import {HeaderMenuItem} from "@carbon/react";
import Search from "../../content/Search";


class NavigationHelper {
    static NAVIGATION = {
        'home': { path: '/', label: 'Home', component: Home, routes: [] },
        'search': { path: '/search', label: 'Search', component: Search, routes: ['/:query'] }
    }
    static getRoutes(internal = false) {
        let routes = [];
        Object.values(NavigationHelper.NAVIGATION).filter(p => internal || !p.internal).forEach(p => {
            const path = internal && p?.internalPath ? p.internalPath : p.path;
            routes.push({...p, path: path, exact: true});
            p.routes.forEach(r => routes.push({...p, path: path + r, exact: false}));
        });

        return routes.map(r => {
            const Component = r.component;
            return <Route exact={r.exact} path={r.path} key={r.path} element={<Component/>}/>;
        });
    }
    static getHeaderMenuItems(internal = false) {
        return Object.values(NavigationHelper.NAVIGATION).filter(p => !p.hidden && (internal || !p.internal)).map(p => {
            const path = internal && p?.internalPath ? p.internalPath : p.path;
            return <HeaderMenuItem element={Link} to={path} key={path}>{p.label}</HeaderMenuItem>;
        });
    }
}

export default NavigationHelper;