import axios from 'axios';

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = true;

const isDEV = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

export const HIVE_SCHEME = isDEV ? 'http' : 'https';
export const HIVE_HOST = isDEV ? 'localhost' : 'hive.x-force.red';
export const X_FORCE_URL = 'https://www.ibm.com/security/xforce';
export const SSO_ENDPOINT = 'https://sso.x-force.red/auth/w3/init/' + HIVE_HOST;
