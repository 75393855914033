import Honeycomb from "../../backend/Honeycomb";
import './acc-card.scss';
import BaseCard from "../BaseCard";


const AccCard = ({query = '', onCount = () => {}, className = '', ...rest}) => {
    const hdb = new Honeycomb();
    return <BaseCard
        query={query} onCount={onCount} className={'xf-account-card ' + className} size="sm" downloadable expands
        onDownload={hdb.account_dl} source={hdb.account} count={hdb.account_count} cancel={hdb.cancel_token}
        title="Data Breach Accounts" description="For all known domains and associated email addresses"
        headers={[
            {key: 'name', header: 'Breach'},
            {key: 'date', header: 'Leaked'},
            {key: 'email', header: 'Email'},
            {key: 'data', header: 'Password'},
        ]}
        parse={results => {
            const rows = [], data = {};
            Object.entries(results).forEach(([k, v]) => {
                let idx = v.data.indexOf(':');
                if (idx < 0) idx = v.data.indexOf(';');
                if (idx < 0) idx = 0;
                rows.push({
                    id: k, name: v.name, email: v.data.substring(0, idx), data: v.data.substring(idx+1),
                    date: v.date.startsWith('1970-01-01') ? '' : v.date.split('T')[0]
                });
                data[k] = v.data;
            });
            return [rows, data];
        }}
        {...rest}
    />
};

export default AccCard;
