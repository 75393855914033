import React, { useState, useEffect } from 'react';
import './search.scss';
import RelCard from "../../components/RelCard";
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import {Responsive, WidthProvider} from "react-grid-layout";
import {Content, ListItem, UnorderedList} from "@carbon/react";
import {Draggable} from "@carbon/icons-react";
import {useParams, useNavigate} from 'react-router-dom';
import DNSCard from "../../components/DNSCard";
import OrgCard from "../../components/OrgCard";
import HostCard from "../../components/HostCard";
import SvcCard from "../../components/SvcCard";
// import CrtCard from "../../components/CrtCard";
import AccCard from "../../components/AccCard";
import SearchBar from "../../components/SearchBar";
import TotalCard from "../../components/TotalCard";


// includes, helper methods and base constants
const _ = require('lodash');

const getFromLS = key => {
    let ls = {};
    if (global.localStorage) {
        try {
            ls = JSON.parse(global.localStorage.getItem("rh-grid-layout")) || {};
        } catch (e) {
            /*Ignore*/
        }
    }
    return ls[key];
}
const saveToLS = (key, value) => {
    if (global.localStorage) {
        global.localStorage.setItem(
            "rh-grid-layout",
            JSON.stringify({
                [key]: value
            })
        );
    }
}

const LAYOUT_KEY = 'grid';
const loadLayout = () => getFromLS(LAYOUT_KEY);
const saveLayout = layout => saveToLS(LAYOUT_KEY, layout);

const DefaultLayout = {
    "lg": [
        {"w": 4, "h": 8, "x": 0, "y": 0, "i": "dns", "moved": false, "static": false},
/*        {"w": 12, "h": 8, "x": 0, "y": 14, "i": "crt", "moved": false, "static": false},*/
        {"w": 4, "h": 6, "x": 0, "y": 8, "i": "host", "moved": false, "static": false},
        {"w": 4, "h": 6, "x": 4, "y": 6, "i": "rel", "moved": false, "static": false},
        {"w": 4, "h": 6, "x": 8, "y": 6, "i": "org", "moved": false, "static": false},
        {"w": 8, "h": 8, "x": 4, "y": 0, "i": "svc", "moved": false, "static": false},
        {"w": 12, "h": 8, "x": 0, "y": 22, "i": "acc", "moved": false, "static": false}
    ]
};
const gridLayout = loadLayout() || DefaultLayout;
const DragGrid = WidthProvider(Responsive);

const gridColumns = {lg: 12, md: 10, sm: 6, xs: 4, xxs: 2};
const emptyTotals = {dns: 0, crt: 0, host: 0, svc: 0, acc: 0, rel: 0, org: 0};
const nullTotals = {dns: -1, crt: -1, host: -1, svc: -1, acc: -1, rel: -1, org: -1};

// basic sub-components
const DragItem = React.forwardRef(({className, children, ...rest}, ref) =>
<div ref={ref} className={'xf-i-card ' + className} {...rest}>
    <div className="xf-drag"><Draggable size={24} /></div>{children}
</div>);

// our search function component
const Search = () => {
    // params
    const { query } = useParams();
    const navigate = useNavigate();
    const handleSearch = value => navigate('/search/' + value.replace('/', '~'));
    const q = query ? query.replace('~', '/') : '';

    // state
    const [layout, setLayout] = useState(_.cloneDeep(gridLayout));
    const [totals, setTotals] = useState({...(query ? nullTotals : emptyTotals)});

    // methods
    //const resetLayout = () => setLayout(_.cloneDeep(DefaultLayout));
    const onLayoutChange = (layout, layouts) => {
        saveLayout(layouts);
        setLayout(layouts);
    };

    const setTotal = (field, total) => setTotals(_totals => ({..._totals, [field]: total}));

    // componentDidMount / componentDidUpdate (if query has changed)
    useEffect(() => setTotals(_.cloneDeep(query ? nullTotals : emptyTotals)), [query]);

    // render
    return (
        <Content>
            <div>
                <div className="rh-search">
                    <div className="cds--data-table-header">
                        <h4 className="cds--data-table-header__title">Search Dashboard</h4>
                        <div className="cds--data-table-header__description">
                            <p>Search for related entities in the database by entering a search query, this can be one of the following:</p>
                            <br/>
                            <UnorderedList className="xf-dt-list">
                                <ListItem>
                                    Domain name - e.g. cc.ibm.com or ibm.com, use a wildcard for all subdomains i.e. *.ibm.com
                                </ListItem>
                                <ListItem>
                                    IP address, range or CIDR - e.g. 10.73.66.77, 10.73.66.0-128, 10.73.66.128/25
                                </ListItem>
                                <ListItem>
                                    IP address plus increment - e.g. 10.73.66.77+13 (equivalent to 10.73.66.77-90)
                                </ListItem>
                                <ListItem>
                                    Email address - e.g. user.name@ibm.com
                                </ListItem>
                            </UnorderedList>
                            <br/>
                            <p>
                                <span className="xf-desc-note">
                                    <strong>Please Note:</strong> This database consists of several terabytes of
                                    data, large datasets may take a while to load, please be patient and avoid
                                    multiple page refreshes if things seem sluggish.
                                </span>
                                <br/>
                            </p>
                            <br/>
                        </div>
                        <div className="xf-i-tool">
                            <SearchBar
                                defaultValue={q} onSubmit={handleSearch}
                                placeholder="Please enter either a domain name, email or IP address"
                            />
                        </div>
                        <div className="xf-i-stats">
                            <TotalCard className="dns" value={totals.dns} label="DNS Records" />
                            <TotalCard className="svc" value={totals.svc} label="Services" />
                            <TotalCard className="host" value={totals.host} label="Hosts" />
                            <TotalCard className="rel" value={totals.rel} label="Associated Domains" />
                            <TotalCard className="org" value={totals.org} label="Corporate Domains" />
                            {/*<TotalCard className="crt" value={totals.crt} label="Certificates" />*/}
                            <TotalCard className="acc" value={totals.acc} label="Accounts" />
                        </div>
                    </div>
                    <div className="xf-i-main">
                        <DragGrid
                            className="layout" draggableHandle=".xf-drag" margin={[15,15]} containerPadding={[0,0]}
                            cols={gridColumns} rowHeight={50} layouts={layout} onLayoutChange={onLayoutChange}
                        >
                            <DragItem key="dns" className="dns" data-grid={{x: 0, y: 0, w: 4, h: 6}}>
                                <DNSCard query={q} onCount={total => setTotal('dns', total)}/>
                            </DragItem>
                            {/*
                                <DragItem key="crt" className="crt" data-grid={{x: 4, y: 0, w: 8, h: 8}}>
                                    <CrtCard query={q} onCount={total => setTotal('crt', total)}/>
                                </DragItem>
                                */}
                            <DragItem key="host" className="host" data-grid={{x: 0, y: 6, w: 4, h: 5}}>
                                <HostCard query={q} onCount={total => setTotal('host', total)}/>
                            </DragItem>
                            <DragItem key="rel" className="rel" data-grid={{x: 0, y: 11, w: 4, h: 5}}>
                                <RelCard query={q} onCount={total => setTotal('rel', total)}/>
                            </DragItem>
                            <DragItem key="org" className="org" data-grid={{x: 0, y: 11, w: 4, h: 5}}>
                                <OrgCard query={q} onCount={total => setTotal('org', total)}/>
                            </DragItem>
                            <DragItem key="svc" className="svc" data-grid={{x: 4, y: 8, w: 8, h: 8}}>
                                <SvcCard query={q} onCount={total => setTotal('svc', total)}/>
                            </DragItem>
                            <DragItem key="acc" className="acc" data-grid={{x: 0, y: 16, w: 12, h: 8}}>
                                <AccCard query={q} onCount={total => setTotal('acc', total)}/>
                            </DragItem>
                        </DragGrid>
                    </div>
                </div>
            </div>
        </Content>
    );
};

export default Search;