import axios from 'axios';
import {HIVE_HOST, HIVE_SCHEME} from "./Config";


export default class Honeycomb
{
    static API_URL = `${HIVE_SCHEME}://${HIVE_HOST}/api/v3`;
    static DEFAULT_PAGE_SIZE = 100;

    constructor() {
        this._cancelToken = null;
        this.safeRequest = this.safeRequest.bind(this);
    }
    clean_query = domain => domain.replace('/', '~');
    async safeRequest(url, config = {}) {
        const CancelToken = axios.CancelToken;
        // create the source
        this.cancel_token();
        let cancel = CancelToken.source();
        this._cancelToken = cancel;
        try {
            const { data } = await axios.get(url, {
                cancelToken: cancel.token, ...config
            });
            cancel.token.throwIfRequested();
            return data;
        } catch (ex) {
            if (!axios.isCancel(ex)) throw ex;
        }
        return {};
    }
    cancel_token = () => {
        //if (this._cancelToken) this._cancelToken.cancel();
        this._cancelToken = null;
    }
    reset_token = () => {
        this.cancel_token();
        this._cancelToken = axios.CancelToken.source();
    }
    request = (url, safe, config = {}) => {
        if (safe) return this.safeRequest(url, config);
        this.reset_token();
        return axios.get(url, {
            cancelToken: this._cancelToken.token, ...config
        }).then(response => response.data);
    }
    download = (url, safe, name, mime = 'text/plain', config = {}) => this.request(
        url, safe, {responseType: 'blob', ...config}
    ).then(data => {
        const url = window.URL.createObjectURL(new Blob([data], {type: mime}));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
    });
    // downloadCSV = (url, safe, name, config = {}) => this.download(url, safe, name, 'text/csv', config);
    downloadCSV = (url, safe, name, config = {}) => {
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
    };

    dns = (domain, page= 1, limit= Honeycomb.DEFAULT_PAGE_SIZE, safe = true) =>
        this.request(`${Honeycomb.API_URL}/dns/${this.clean_query(domain)}/page/${page}/limit/${limit}`, safe);
    crt = (domain, page= 1, limit= Honeycomb.DEFAULT_PAGE_SIZE, safe = true) =>
        this.request(`${Honeycomb.API_URL}/crt/${this.clean_query(domain)}/page/${page}/limit/${limit}`, safe);
    host = (domain, page= 1, limit= Honeycomb.DEFAULT_PAGE_SIZE, safe = true) =>
        this.request(`${Honeycomb.API_URL}/host/${this.clean_query(domain)}/page/${page}/limit/${limit}`, safe);
    svc = (domain, page= 1, limit= Honeycomb.DEFAULT_PAGE_SIZE, safe = true) =>
        this.request(`${Honeycomb.API_URL}/svc/${this.clean_query(domain)}/page/${page}/limit/${limit}`, safe);
    account = (domain, page= 1, limit= Honeycomb.DEFAULT_PAGE_SIZE, safe = true) =>
        this.request(`${Honeycomb.API_URL}/account/${this.clean_query(domain)}/page/${page}/limit/${limit}`, safe);
    rel = (domain, page= 1, limit= Honeycomb.DEFAULT_PAGE_SIZE, safe = true) =>
        this.request(`${Honeycomb.API_URL}/rel/${this.clean_query(domain)}/page/${page}/limit/${limit}`, safe);
    org = (domain, page= 1, limit= Honeycomb.DEFAULT_PAGE_SIZE, safe = true) =>
        this.request(`${Honeycomb.API_URL}/org/${this.clean_query(domain)}/page/${page}/limit/${limit}`, safe);

    dns_count = (domain, safe = true) =>
        this.request(`${Honeycomb.API_URL}/dns/${this.clean_query(domain)}/count`, safe);
    crt_count = (domain, safe = true) =>
        this.request(`${Honeycomb.API_URL}/crt/${this.clean_query(domain)}/count`, safe);
    host_count = (domain, safe = true) =>
        this.request(`${Honeycomb.API_URL}/host/${this.clean_query(domain)}/count`, safe);
    svc_count = (domain, safe = true) =>
        this.request(`${Honeycomb.API_URL}/svc/${this.clean_query(domain)}/count`, safe);
    account_count = (domain, safe = true) =>
        this.request(`${Honeycomb.API_URL}/account/${this.clean_query(domain)}/count`, safe);
    rel_count = (domain, safe = true) =>
        this.request(`${Honeycomb.API_URL}/rel/${this.clean_query(domain)}/count`, safe);
    org_count = (domain, safe = true) =>
        this.request(`${Honeycomb.API_URL}/org/${this.clean_query(domain)}/count`, safe);

    dns_dl = (domain, name = 'results', safe = true) =>
        this.downloadCSV(`${Honeycomb.API_URL}/dns/${this.clean_query(domain)}/csv`, safe, name + '.csv');
    crt_dl = (domain, name, safe = true) =>
        this.downloadCSV(`${Honeycomb.API_URL}/crt/${this.clean_query(domain)}/csv`, safe, name + '.csv');
    host_dl = (domain, name, safe = true) =>
        this.downloadCSV(`${Honeycomb.API_URL}/host/${this.clean_query(domain)}/csv`, safe, name + '.csv');
    svc_dl = (domain, name, safe = true) =>
        this.downloadCSV(`${Honeycomb.API_URL}/svc/${this.clean_query(domain)}/csv`, safe, name + '.csv');
    account_dl = (domain, name, safe = true) =>
        this.downloadCSV(`${Honeycomb.API_URL}/account/${this.clean_query(domain)}/csv`, safe, name + '.csv');
    rel_dl = (domain, name, safe = true) =>
        this.downloadCSV(`${Honeycomb.API_URL}/rel/${this.clean_query(domain)}/csv`, safe, name + '.csv');
    org_dl = (domain, name, safe = true) =>
        this.downloadCSV(`${Honeycomb.API_URL}/org/${this.clean_query(domain)}/csv`, safe, name + '.csv');

    status = (safe = true) => this.request(`${Honeycomb.API_URL}/status`, safe);
}
