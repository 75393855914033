import Honeycomb from "../../backend/Honeycomb";
import './host-card.scss';
import BaseCard from "../BaseCard";
import * as _ from 'lodash';


const HostCard = ({query = '', onCount = () => {}, className = '', ...rest}) => {
    const hdb = new Honeycomb();
    return <BaseCard
        query={query} onCount={onCount} className={'xf-host-card ' + className}
        downloadable onDownload={q => hdb.host_dl(q, `host-results-${q}.csv`)}
        source={hdb.host} count={hdb.host_count} cancel={hdb.cancel_token}
        title="Hosts" description="IPv4 addresses of known hosts"
        parse={results => [(_.isEmpty(results) ? [] : results).map(i => ({id: i, ipv4: i})), {}]}
        headers={[
            {key: 'ipv4', header: 'Address'},
        ]}
        {...rest}
    />
};

export default HostCard;
