import React, {useEffect, useState, useContext, useRef} from 'react';
import './base-card.scss';
import DataCard from "../DataCard";

import SessionContext from "../../helpers/SessionContext";
import Loading from "../Loading";
import {PAGINATION_TEMPLATE} from "../DataCard/DataCard";


const BaseCard = (
    {
        query = '',
        source = () => ({}),
        count = () => 0,
        cancel = () => {},
        parse = (results, headers, dataKey) => {
            // generic parser that builds rows based on the header list
            const rows = [], data = {};
            for (const [k, v] of Object.entries(results)) {
                const row = {id: k};
                for (const header of headers) row[header.key] = v[header.key];
                rows.push(row);
                data[k] = dataKey ? v[dataKey] : v;
            }
            return [rows, data];
        },
        onCount = () => {},
        downloadable = false,
        onDownload = () => {},
        generator = (row, data) => <span>{row.id in data && data[row.id]}</span>,
        dataKey = '',
        expands = false,
        size = 'xs',
        title = 'Data Records',
        description = 'Data records',
        className = '',
        headers = [
            {key: 'key', header: 'Key'},
        ]
    }
) => {
    // context
    const ctx = useContext(SessionContext);

    // state
    const [data, setData] = useState({});
    const [pages, setPages] = useState({...PAGINATION_TEMPLATE});
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const prevPageSizeRef = useRef(PAGINATION_TEMPLATE.page_size);

    // stuff
    const hasQuery = query.trim().length > 0;
    const rawQuery = query.replace('~', '/');
    const expandable = expands && data;

    const pageNumber = pages?.current_page ?? 1;
    const pageSize = pages?.page_size ?? PAGINATION_TEMPLATE.page_size;
    const pageSizeChanged = prevPageSizeRef.current !== pageSize;

    // methods
    const getPageData = () => source(query, pageNumber, pageSize).then(
        r => {
            const result = parse(r?.data ?? {}, headers ?? [], dataKey);
            setData(d => {
                d[pageNumber] = result;
                return d;
            });
            setLoading(false);
            setMessage('');
        }
    ).catch(ctx.networkFaultHandler);

    const getPageCount = () => count(query).then(
        r => {
            setPages(pages => {
                const lastPage = Math.ceil(r.total / pages.page_size);

                return {
                    ...(pages ?? PAGINATION_TEMPLATE),
                    total: r.total, total_pages: lastPage,
                    has_prev: pages.current_page > 1, has_next: pages.current_page < lastPage,
                    item_count: pages.current_page === lastPage ? (r.total / pages.page_size) : pages.page_size
                };
            });
            onCount(r.total);
        }
    ).catch(ctx.networkFaultHandler);

    const onPageChange = p =>
        (p.page !== pages.current_page || p.pageSize !== pages.page_size) &&
        setPages(pages => ({...pages, current_page: p.page, page_size: p.pageSize}));

    // lifecycle methods i.e. componentDidMount / componentDidUpdate
    useEffect(() => {
            // handle query changes i.e. update totals and get the first page
            setData({});

            if (!hasQuery) {
                setLoading(false);
                setMessage('');
                setPages({...PAGINATION_TEMPLATE});
                return cancel;
            }

            setLoading(true);
            setMessage('Searching: ' + rawQuery);
            setPages({...PAGINATION_TEMPLATE, total: -1});  // -1 means we're loading totals

            getPageData();
            getPageCount();

            return cancel; // componentWillUnmount
        },
        [query]
    );

    useEffect(() => {
        prevPageSizeRef.current = pageSize;
    }, [pages?.page_size]);

    useEffect(() => {
            // handle page changes i.e. only update the page data, not totals
            if (!hasQuery || (!pageSizeChanged && (pageNumber in data))) return cancel;

            if (pageSizeChanged) setData({});
            setLoading(true);
            setMessage('Searching: ' + rawQuery);

            getPageData();

            return cancel;
        },
        [pages?.current_page, pages?.page_size]
    );

    const [rows, _data] = data[pageNumber] ?? [[], {}];

    return (
        <div className={'xf-data-card ' + className}>
            {loading && <Loading hasLogo={true} modal={false} message={message}/>}
            <DataCard
                rows={rows} headers={headers} size={size} expandable={expandable} title={title}
                contentGenerator={row => generator(row, _data)} pagination={pages} onPageChange={onPageChange}
                description={(
                    <span>
                        {description}
                        {(pages?.total ?? -1) < 0 ? null : " (" + pages?.total?.toLocaleString() + " entries)"}
                    </span>
                )}
                downloadable={downloadable} onDownload={() => onDownload(query.replace('/', '~'))}
            />
        </div>
    );
};

export default BaseCard;
