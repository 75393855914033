import Honeycomb from "../../backend/Honeycomb";
import './org-card.scss';
import BaseCard from "../BaseCard";


const OrgCard = ({query = '', onCount = () => {}, className = '', ...rest}) => {
    const hdb = new Honeycomb();
    return <BaseCard
        query={query} onCount={onCount} className={'xf-org-card ' + className} downloadable
        onDownload={hdb.org_dl} source={hdb.org} count={hdb.org_count} cancel={hdb.cancel_token}
        title="Corporate Domains" description="Domains registered using a corporate email address"
        headers={[
            {key: 'domain', header: 'Domain'},
            {key: 'email', header: 'Owner'},
            {key: 'name', header: 'Name'},
        ]}
        {...rest}
    />
};

export default OrgCard;
