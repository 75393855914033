import './loading.scss';

const Loading = (
    {message = 'Loading...', hasLogo = false, modal = false, className = ''}
) => <div className={'container loading-container ' + (modal ? 'loading-modal ' : '') + className}>
    <div className={'loading-spinner d-flex justify-content-center mt-5 ' + (hasLogo ? 'loading-logo ' : '')}>
        <div className="loading-inner">
            {hasLogo ? <>
                    <div className="xfr-logo-box like-a-record-baby-right-round-round-round">
                        <div/><div/><div/>
                        <div/><div/><div/>
                        <div/><div/><div/>
                    </div>{message && (<h1>{message}</h1>)}
                </>
                :
                <>
                    <div className="lds-ripple"><div/><div/></div>
                    {message && (<h1>{message}</h1>)}
                </>
            }
        </div>
    </div>
</div>;

export default Loading;
