import React, {Component} from 'react';
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";

import './particle-banner.scss';


const particlesInit = async (main) => {
    // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(main);
};

class ParticleBanner extends Component {
    static defaultProps = {
        className: '',
        lineColour: '#ff5722',
        strokeColour: '#ff9800',
        fillColour: '#ff9800',
        strokeWidth: 2,
        initialParticleCount: 100,
        particleCount: 1,
        particleLimit: 500,
        particleFrequency: 0.1,
        polyCount: 6,
        speed: 4,
        fpsLimit: 30,
        attract: false,
        collisions: true,
        fullScreen: false
    };
    constructor(props) {
        super(props);

        const { particleFrequency, particleCount, fpsLimit, fullScreen } = this.props;

        this.options = {
            fullScreen: {
                enable: fullScreen
            },
            background: {
                color: '#161616'
            },
            pauseOnBlur: true,
            pauseOnOutsideViewport: true,
            detectRetina: true,
            particles: this.particles(),
            fpsLimit: fpsLimit,
            interactivity: {
                detectsOn: "canvas",
                events: {
                    onhover: {
                        enable: true,
                        mode: [
                            "grab",
                            "bubble",
                            "repulse"
                        ]
                    },
                    onclick: {
                        enable: true,
                        mode: "push"
                    },
                    resize: true
                },
                modes: {
                    grab: {
                        distance: 400,
                        line_linked: {
                            opacity: 0.7
                        }
                    },
                    bubble: {
                        distance: 600,
                        size: 12,
                        duration: 1,
                        opacity: 0.8,
                        speed: 2
                    },
                    repulse: {
                        distance: 30,
                        duration: 0.8
                    },
                    push: {
                        quantity: 20
                    },
                    remove: {
                        quantity: 10
                    }
                }
            },
            emitters: [
                {
                    direction: "top-right",
                    position: { x: 0, y: 100 },
                    particles: this.particles(),
                    rate: {
                        delay: particleFrequency,
                        quantity: particleCount,
                    },
                    startCount: 0,
                }
            ]
        };
    }
    particles = () => {
        const {
            lineColour, strokeColour, fillColour, strokeWidth,
            polyCount, speed, attract, collisions, particleLimit, initialParticleCount
        } = this.props;

        return {
            collisions: {
                enable: collisions,
                mode: 'bounce'
            },
            number: {
                value: initialParticleCount,
                limit: particleLimit,
                density: {
                    enable: true,
                    value_area: 500
                }
            },
            color: {
                value: fillColour
            },
            shape: {
                type: "polygon",
                stroke: {
                    width: strokeWidth,
                    color: strokeColour
                },
                polygon: {
                    sides: polyCount
                }
            },
            opacity: {
                value: 0.5,
                random: true
            },
            size: {
                value: 10,
                random: true
            },
            line_linked: {
                enable: false,
                distance: 200,
                color: lineColour,
                opacity: 0.3,
                width: 1
            },
            move: {
                enable: true,
                speed: speed,
                direction: "right",
                random: true,
                straight: true,
                out_mode: "bounce-vertical",
                bounce: true,
                attract: {
                    enable: attract,
                    rotateX: 600,
                    rotateY: 1200
                }
            }
        };
    }
    render() {
        const { className } = this.props;

        return (
            <div className={'particle-banner ' + className}>
                <Particles init={particlesInit} options={this.options} />
            </div>
        );
    }
}
export default ParticleBanner;
