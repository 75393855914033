import React from 'react';
import {Content, FlexGrid, Row, Column} from "@carbon/react";
import {Link, Rocket, FingerprintRecognition} from "@carbon/icons-react";
import './home.scss';
import ParticleBanner from "../../components/ParticleBanner";


class Home extends React.Component {
    render() {
        return (
            <Content className="xfr-landing-page">
                <FlexGrid fullWidth condensed narrow>
                <Row className="xfr-landing-page__banner">
                    <ParticleBanner
                        lineColour="#ba1b23" strokeColour="#da1e28" fillColour="#fa4d56"
                        speed={4} particleCount={1} particleFrequency={.1} particleLimit={250}
                    />
                    <Column lg={16} className="xfr-landing-page__banner_content">
                        <h1 className="xfr-landing-page__heading">
                            Welcome to <span className="rh-red">Red</span><span className="rh-hive">Hive</span>
                        </h1>
                        <h2 className="xfr-landing-page__subheading">The X-Force Red Open Source Intelligence Database</h2>
                    </Column>
                </Row>
                <Row className="xfr-landing-page-intro">
                    <Column xlg={11} lg={10} md={6} sm={4}>
                        <h2 className="xfr-landing-page__subheading">
                            What is RedHive?
                        </h2>
                        <p className="xfr-landing-page__p">
                            Welcome to X-Force Red's Open Source Intelligence Database. Powered by the purpose-built
                            Honeycomb database engine, RedHive can search terabytes of raw data in the blink of an eye.

                            Several public data sources are parsed, merged, and loaded into Honeycomb clusters.
                            This system allows quick access to information about a target host, network, or domain, its
                            related assets, and any other relevant data found in public records and data breaches.
                        </p>
                    </Column>
                    <Column xlg={5} lg={6} md={2} sm={0}>
                        <div className="hive-ico">
                            <svg className="hivelogo" width="25" height="25" role="img" viewBox="0 0 500 500" version="1.1">
                                <polygon stroke="#f4f4f4" fill="none" strokeWidth="25" points="288.4225616455078,80.70246887207031 184.02182006835938,23.008636474609375 80.23545837402344,82.64189910888672 80.84941101074219,199.9697265625 185.2501678466797,257.66357421875 289.0369110107422,198.02952575683594 " id="svg_2"/>
                                <polygon stroke="#97354d" fill="none" strokeWidth="25" points="211.57749938964844,419.29754638671875 315.97825622558594,476.9913330078125 419.76454162597656,417.3580322265625 419.1505889892578,300.0302734375 314.7499237060547,242.33645629882812 210.96310424804688,301.970458984375 " id="svg_3"/>
                            </svg>
                        </div>
                    </Column>
                </Row>
                <Row className="info-section">
                    <Column max={1} lg={2} md={4}>
                        <h3 className="info-section__heading">Open Source Intelligence?</h3>
                    </Column>
                    <Column max={5} lg={3} md={6} className="info-card">
                        <h4 className="info-card__heading">
                            Data breach <strong>Accounts</strong>
                        </h4>
                        <p className="info-card__body">
                            When a company faces a data breach, sometimes the information extracted during these
                            breaches is made available on the dark web, normally for a fee. These databases come in
                            many different forms, from simple text files containing credentials only, to complete
                            database backups containing raw SQL. We parse this data to extract the accounts, their
                            usernames, email addresses, hashes, salts, and plaintext passwords if they are available.
                        </p>
                        <FingerprintRecognition size={32} />
                    </Column>
                    <Column max={5} lg={3} md={6} className="info-card">
                        <h4 className="info-card__heading">
                            Known active <strong>Domains</strong>
                        </h4>
                        <p className="info-card__body">
                            DNS information from various sources including internet-wide port scans, DNS history, WHOIS
                            records, SSL certificates, web scraping, public documents, and data breaches is amalgamated
                            into a single repository of known fully qualified domain names. This allows us to query
                            this dataset to obtain a list of known subdomains for a given domain name or
                            cross-reference ownership information to find domains owned by the same entity.
                        </p>
                        <Link size={32} />
                    </Column>
                    <Column max={5} lg={3} md={6} className="info-card">
                        <h4 className="info-card__heading">
                            Internet-facing <strong>Services</strong>
                        </h4>
                        <p className="info-card__body">
                            Internet-wide survey scans of the entire IPv4 public address space have been common practice for
                            several years. This information allows us to find services exposed to the internet without
                            the need to actively probe a target network. We scan the SSL certificates exposed by these
                            services as well as any raw data returned, such as HTML content, to extract information
                            about the target network, hosts, exposed ports, and the services found on those ports.
                        </p>
                        <Rocket size={32} />
                    </Column>
                </Row>
                <Row className="xfr-landing-page__r3">
                    <small>
                        <strong>Please Note:</strong> This is an alpha release so please bear with us whilst we make changes to content, features and available datasets.
                    </small>
                </Row>
                <Row as="footer" className="xfr-footer">
                    <div className="xfr-fl"><strong>RedHive |</strong>| The X-Force Red Open Source Intelligence Database</div>
                    <div className="xfr-author">
                      <span>
                          Built with <span className="xf-icon">♥</span> by
                          <a className="xf-repo" href="https://github.ibm.com/Ryan-Ward1/redhive" target="_blank" rel="noopener noreferrer">Zo0x</a>.
                      </span>
                    </div>
                </Row>
                </FlexGrid>
            </Content>
        );
    }
}

export default Home;